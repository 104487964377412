const serviceableCitiesLocationData = [
  {
    city: "mumbai",
    img: "https://img.ebonow.com/custom/indiaGate.webp",
    label: "Mumbai",
    lat: 19.04360522319033,
    lng: 73.07177922952339,
    addressLine1: "Mumbai",
    addressLine2: "Mumbai city",
    state: "Maharashtra",
    pincode: "410210",
  },
  {
    city: "pune",
    img: "https://img.ebonow.com/custom/pune_place.webp",
    label: "Pune",
    lat: 18.483849477727237,
    lng: 73.81012027013382,
    addressLine1: "Pune",
    addressLine2: "Pune city",
    state: "Maharashtra",
    pincode: "411038",
  },
  {
    city: "siliguri",
    img: "https://img.ebonow.com/Posters/cityicon_siliguri.webp",
    label: "Siliguri",
    lat: 26.71783896797181,
    lng: 88.42246533853076,
    addressLine1: "Siliguri",
    addressLine2: "Siliguri city",
    state: "West Bengal",
    pincode: "734001",
  },
];

export { serviceableCitiesLocationData };
