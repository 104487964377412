import React from "react";
import { serviceableCitiesLocationData } from "../../../../../utils/eboUtils";

export default function ServiceableCities({ states, setStates }) {
  if (states.showServiceCites) {
    return (
      <div className="flex flex-col w-full bg-gradient-to-b rounded-xl px-4 mt-2  from-blue-50 to-white">
        <div className="w-full text-[.875rem] px-1  text-[grey] pt-2  ">
          Serviceable city
        </div>
        {/* serviceable cities container */}
        <div className="flex  text-[.875rem] gap-6 mt-2 w-full">
          {serviceableCitiesLocationData.map((curr, idx) => (
            <div
              onClick={() => {
                setStates((p) => ({
                  ...p,
                  lat: curr.lat,
                  lng: curr.lng,
                  showMap: true,
                  defaultUi: false,
                  isPredictionsShow: false,
                  loadingPredictions: false,
                  showServiceCites: false,
                  isChangeMapPosition: true,
                }));
              }}
              key={idx}
              className="flex relative items-center gap-2 flex-col "
            >
              <img
                className="rounded-xl w-[96px] h-[66px]"
                src={curr.img}
                alt="siliguri"
              />
              <span>{curr.label}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
