//
import axios from "axios";
import { insideHubBoundary } from "./availablityChecks";
import moment from "moment";
import { serviceableCitiesLocationData } from "../../utils/eboUtils";

export const handleDefaultLocationFetch = async (locationData, ipInfo) => {
  try {
    const LC_TEMP_CART = JSON.parse(localStorage?.getItem("LC_TEMP_CART"));

    // Retrieve location from localStorage
    const localstorageLocation = JSON.parse(localStorage.getItem("U_LOCATION"));
    // Set selectedLocation based on localStorage or IP geolocation
    let selectedLocation;
    let isDefaultAddress = false;
    // if (!isGoogleMapsScriptLoaded) {
    //   await loadGoogleMapScriptPromise();
    // }

    // const currentLocationInfo = await getCurrentLocation();

    if (locationData.selectedLocation?.lat) {
      selectedLocation = locationData.selectedLocation;
    } else if (LC_TEMP_CART?.bookingAddress?.locationv2?.lat) {
      selectedLocation = LC_TEMP_CART?.bookingAddress?.locationv2;
    } else if (localstorageLocation?.lat) {
      selectedLocation = localstorageLocation;
    }

    // If selectedLocation doesn't have lat, fetch from IP geolocation
    if (!selectedLocation?.lat) {
      if (ipInfo?.state?.toLowerCase()?.trim() === "west bengal") {
        selectedLocation = serviceableCitiesLocationData.find(
          (_) => _.city === "siliguri"
        );
      } else if (ipInfo?.city?.toLowerCase()?.includes("pune")) {
        selectedLocation = serviceableCitiesLocationData.find(
          (_) => _.city === "pune"
        );
      } else {
        selectedLocation = serviceableCitiesLocationData.find(
          (_) => _.city === "mumbai"
        );
      }
      isDefaultAddress = true;
    }

    // Check if the location falls within a specific hub's boundary
    let isPointInPolygon = await insideHubBoundary([
      selectedLocation.lng,
      selectedLocation.lat,
    ]);

    // If not, set default location for Mumbai and check again
    if (!isPointInPolygon.isInside) {
      selectedLocation = serviceableCitiesLocationData.find(
        (_) => _.city === "mumbai"
      );
      isPointInPolygon = await insideHubBoundary([
        selectedLocation.lng,
        selectedLocation.lat,
      ]);
    }

    // Update localStorage and state with the selected location
    localStorage.setItem(
      "U_LOCATION",
      JSON.stringify({ ...selectedLocation, isDefault: isDefaultAddress })
    );

    return {
      hubId: isPointInPolygon.foundInWhichHub,
      locationObject: {
        ...selectedLocation,

        hubId: isPointInPolygon.foundInWhichHub,
      },
    };
  } catch (error) {
    console.error(error);
  }
};

export const getIPInfo = async () => {
  try {
    let user = localStorage?.getItem("user");

    if (user) {
      user = JSON.parse(user);
    }

    const localStorageIPInfo = localStorage.getItem("I_INFO");
    let ipInfo;
    let todayTraficFlag = false;

    if (localStorageIPInfo) {
      ipInfo = JSON.parse(localStorageIPInfo); // ---> {city, state, lat, lng, date}
      // if the date is today then we will not call the api ( the traffic already logged for today)
      todayTraficFlag = !moment().isSame(ipInfo.date, "day");

      if (todayTraficFlag) {
        // update the date to today in localstorage
        let updatedIPInfo = { ...ipInfo, date: moment().toISOString() };
        localStorage.setItem("I_INFO", JSON.stringify(updatedIPInfo));
      }
    } else {
      const ipapiRes = await axios.get("https://ipapi.co/json/");
      const ipInfoFromRes = {
        city: ipapiRes?.data?.city,
        state: ipapiRes?.data?.region,
        lat: ipapiRes?.data?.latitude,
        lng: ipapiRes?.data?.longitude,
        date: moment().toISOString(),
      };
      localStorage.setItem("I_INFO", JSON.stringify(ipInfoFromRes));
      ipInfo = ipInfoFromRes;
      todayTraficFlag = true;
    }

    ipInfo["todayTraficFlag"] = todayTraficFlag;
    return ipInfo;
  } catch (error) {
    console.error(error);
    return null;
  }
};
